export const Navigation = (props) => {
    return (
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container nav-header'>
          <div className='navbar-header col-md-1'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <a className='navbar-brand page-scroll' href='#page-top'>
            <img src={props.data ? props.data.img : 'img/logo-leut.png'} alt='...' className='team-img-nav' />
            </a>{' '}

              
          </div>
          <div className='social-top col-md-2'>
            <a href={props.data ? props.data.email : '/'} >
              <i className='fa fa-at'></i>
            </a>
            <a href={props.data ? props.data.phone : '/'} >
              <i className='fa fa-phone'></i>
            </a>
            <a href={props.data ? props.data.maps : '/'} target="_blank" rel="noopener noreferrer">
              <i className='fa fa-map-marker'></i>
            </a>
          </div>
          <div className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'>
            <ul className='nav navbar-nav navbar-right'>
            <li>
                <a href='#page-top' className='page-scroll'>
                  Inicio
                </a>
              </li>
              <li>
                <a href='#features' className='page-scroll'>
                  Historia
                </a>
              </li>
              <li>
                <a href='#services' className='page-scroll'>
                  Servicios
                </a>
              </li>
              <li>
                <a href='#advisory' className='page-scroll'>
                  Asesorías
                </a>
              </li>
              <li>
                <a href='#team' className='page-scroll'>
                  Directorio
                </a>
              </li>
              <li>
                <a href='#jobBank' className='page-scroll'>
                  Bolsa de Trabajo
                </a>
              </li>
              <li>
                <a href='#contact' className='page-scroll'>
                  Contacto
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
