export const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.email}-${i}`} className='col-md-6 team'>
                  <div className='thumbnail'>
                    {' '}
                    <div className='caption'>
                      <h4>{d.name}</h4>
                          <div className='row social'>
                          <p>
                          </p>
                          
                          <div className='col-md-12 social'>
                          <a href={d ? d.email : '/'}>
                            {d.correo}
                          </a>
                          </div>
                          <br />
                          <div className='col-md-12 social'>
                          <a href={d ? d.phone : '/'}>
                          Ext: {d.ext}
                          </a>
                          </div>
                          
                          </div>
                    </div>
                  </div>
                </div>
              ))
            : 'cargando'}
        </div>
      </div>
    </div>
    )
}
 