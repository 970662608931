export const About = (props) => {
    return (
        <div id='about'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-12 col-md-6'>
                {' '}
                <img src='img/about.jpg' className='img-responsive' alt='' />{' '}
              </div>
              <div className='col-xs-12 col-md-6'>
                <div className='about-text'>
                  <h2>Acerca de nosotros:</h2>
                  <p>{props.data ? props.data.paragraph : 'cargando...'}</p>
                  <h3>Nuestros valores</h3>
                  <div className='list-style'>
                    <div className='col-lg-6 col-sm-6 col-xs-12'>
                      <ul>
                        {props.data
                          ? props.data.Why.map((d, i) => (
                              <li key={`${d}-${i}`}>{d}</li>
                            ))
                          : 'cargando'}
                      </ul>
                    </div>
                    <div className='col-lg-6 col-sm-6 col-xs-12'>
                      <ul>
                        {props.data
                          ? props.data.Why2.map((d, i) => (
                              <li key={`${d}-${i}`}> {d}</li>
                            ))
                          : 'cargando'}
                      </ul>
                    </div>
                    
                  </div>
                  <div className='row'>
                    <a 
                        href='#contact'
                        className='btn btn-custom btn-lg page-scroll'>
                      Formulario de contacto
                    </a>{' '}
                  </div>
                </div>
              </div>

              

            </div>
          </div>
        </div>
      )
}   