
export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title-s'>
          
          <h2>Identifica tu Trámite</h2>
          
          <img src='img/iso.png' className='' alt='' />{' '}
          <p>Notaria Certificada.</p>
          <p>
            Somos la única Notaría de San Juan del Río que ha sido certificada con ISO 9001: 2008, lo cual te brinda la confianza 
            de que cumple con los más altos estándares en sus procesos 
            internos y de atención a clientes.
          </p>
        </div>
      </div>
      <section>
        <div className='container-services-s'>

          <h2>Poderes</h2>

            <div className="col-lg-4 col-md-4 col-sm-4">
                <h3
                  data-target='#modal-requi-pgpf'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Poder Otorgado por Persona Física
                </h3>
            </div> 

            <div className="col-lg-4 col-md-4 col-sm-4">
                <h3
                  data-target='#modal-requi-pgpm'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Poder Otorgado por Persona Moral
                </h3>
            </div> 

            <div className="col-lg-4 col-md-4 col-sm-4">
                <h3
                  data-target='#modal-requi-pgeliad'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Poder especial, limitado e irrevocable de dominio
                </h3>
            </div> 
          </div>  

          </section>
          <section>
          <div className='container-services-s'>
            <h2>Testamentos</h2>

            <div className="col-lg-12 col-md-12 col-sm-12">
                <h3
                  data-target='#modal-requi-tpa'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Testamento Público Abierto
                </h3>
            </div> 
          </div>
      </section>
      <section>
          <div className='container-services-s'>
            <h2>Compraventas</h2>

            <div className="col-lg-6 col-md-6 col-sm-6">
                <h3
                  data-target='#modal-requi-cv'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Compraventa
                </h3>
            </div> 
            <div className="col-lg-6 col-md-6 col-sm-6">
                <h3
                  data-target='#modal-requi-cvi'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Compraventa INFONAVIT
                </h3>
            </div> 
          </div>
      </section>
      <section>
          <div className='container-services-s'>
            <h2>Ratificaciones</h2>

            <div className="col-lg-6 col-md-6 col-sm-6">
                <h3
                  data-target='#modal-requi-ratPf'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Ratificación de Firmas (Persona Física)
                </h3>
            </div> 
            <div className="col-lg-6 col-md-6 col-sm-6">
                <h3
                  data-target='#modal-requi-r atPm'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Ratificación de Firmas (Persona Moral)
                </h3>
            </div> 
          </div>
      </section>
      <section>
          <div className='container-services-s'>
            <h2>Cancelacion de Hipotecas</h2>

            <div className="col-lg-6 col-md-6 col-sm-6">
                <h3
                  data-target='#modal-requi-chFov'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Cancelación de Hipoteca FOVISSSTE
                </h3>
            </div> 
            <div className="col-lg-6 col-md-6 col-sm-6">
                <h3
                  data-target='#modal-requi-chBnc'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Cancelación de Hipoteca Bancaria
                </h3>
            </div> 
          </div>
      </section>
      <section>
          <div className='container-services-s'>
            <h2>Sucesiónes</h2>

            <div className="col-lg-12 col-md-12 col-sm-12">
                <h3
                  data-target='#modal-requi-suc'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Sucesión Testamentaria / Intestamentaria
                </h3>
            </div> 
          </div>
      </section>
      <section>
          <div className='container-services-s'>
            <h2>Constitucion de Sociedades</h2>

            <div className="col-lg-12 col-md-12 col-sm-12">
                <h3
                  data-target='#modal-requi-soc'
                  data-toggle='modal' 
                  className='btn btn-lg page-scroll'>
                  Constitución de Sociedad
                </h3>
            </div> 
          </div>
      </section>
    </div>      
  )
}





