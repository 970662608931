import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  whatsapp: '',
  tramite: '',
  message: ''
}

export const Contact = (props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState(initialState)
  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setFormData(initialState)

  const handleSubmit = (e) => {
      e.preventDefault()
  
      const validationErrors = validateFormData(formData)
      if (Object.keys(validationErrors).length === 0) {
          console.log(formData)
          emailjs
              .sendForm('service_s91kygr', 'template_c0m0q1m', e.target, 'PFD5jy0HJyCe73WjU')
              .then(
                  (result) => {
                      console.log(result.text)
                      clearState()
                      setShowSuccessModal(true);
                      setShowModal(true); // Mostrar el modal
                      setFormSubmitted(true); // Marcar el formulario como enviado
                      setTimeout(() => {
                          window.location.reload(); // Recargar la página después de 3 segundos
                      }, 2000);
                  },
                  (error) => {
                      console.log(error.text)
                  }
              )
      } else {
          setErrors(validationErrors)
      }
  }

  const reloadPage = () => {
    setShowSuccessModal(false);
    setShowModal(false);
    setFormSubmitted(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000); // 2 segundos antes de recargar la página
  };

  const validateFormData = (data) => {
    const errors = {}
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!data.name.trim()) {
      errors.name = 'El nombre es requerido'
    }
    if (!data.email.trim()) {
      errors.email = 'El correo electrónico es requerido'
    } else if (!emailRegex.test(data.email.trim())) {
      errors.email = 'El correo electrónico no tiene un formato válido'
    }
    if (!data.whatsapp.trim()) {
      errors.whatsapp = 'El número de Whatsapp es requerido'
    }
    if (!data.tramite.trim()) {
      errors.tramite = 'El trámite es requerido'
    }
    if (!data.message.trim()) {
      errors.message = 'El mensaje es requerido'
    }
    return errors
  }

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className="col-md-1"></div>
          <div className='col-md-10'>
            <div className='row'>
              <div className='section-title'>
                <h2>Contacto</h2>
                <p>Dejanos tus datos, para comunicarnos contigo.</p>
              </div>
              <form encType="multipart/form-data" name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Nombre'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'>{errors.name}</p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Correo Electrónico'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'>{errors.email}</p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='whatsapp'
                        name='whatsapp'
                        className='form-control'
                        placeholder='Whatsapp'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'>{errors.whatsapp}</p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='tramite'
                        name='tramite'
                        className='form-control'
                        placeholder='Trámite'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'>{errors.tramite}</p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Mensaje'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'>{errors.message}</p>
                </div>
                <div id='success'></div>
                <button
                  id='modal-btn'
                  type='submit'
                  className='btn-custom btn-lg'
                  disabled={formSubmitted}  
                >
                  Enviar mensaje
                </button>
              </form>
              <div className="modal fade" id="successModal" tabIndex="-1" role="dialog" aria-labelledby="successModalLabel" aria-hidden="true" style={{ display: showSuccessModal ? 'block' : 'none' }}>
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="successModalLabel">Mensaje Enviado</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowSuccessModal(false)}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      Su mensaje ha sido enviado correctamente.
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={reloadPage}>Cerrar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li className='col-md-12'>
                    <a href={props.data ? props.data.maps : '/'} target="_blank" rel="noopener noreferrer">
                      Horario | Lunes a Viernes. de 9:00 Hrs a 15:00 Hrs y 16:00 Hrs a 18:00 Hrs. | Sab. de 10:00 hrs a 13:00 hrs.
                    </a>
                  </li>
                  <li className='col-md-12'>
                    <a href={props.data ? props.data.email : '/'}>
                      Correo | comunicacion@notaria04sjr.com
                    </a>
                  </li>
                  <li className='col-md-12'>
                    <a href={props.data ? props.data.phone : '/'}>
                      Tel | 427 272 7777 |
                    </a>
                  </li>
                  <li className='col-md-12'>
                    <a href={props.data ? props.data.maps : '/'} target="_blank" rel="noopener noreferrer">
                      Dirección | Av. Juárez número 48, San Juan del Río, Querétaro.
                    </a>
                  </li>
                  <li className='col-md-12'>
                  <a href={props.data ? props.data.whatsapp : '/'}>
                      Whatsapp | 442 190 4451
                    </a>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className='whatsapp-qr'>
                          <div className='hover-qr'>
                            {' '}
                            <a
                              href='img/whatsapp-qr.png'
                              title='whatsapp Qr'
                              data-lightbox-gallery='whatsapp-qr'
                            >
                              <img
                                src='img/whatsapp-qr.png'
                                className='img-whatsapp-qr'
                                alt='Ver imagen'
                              />{' '}
                            </a>{' '}
                          </div>
                        </div> 
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2024 Notaría 4, San Juan del Río, Qro.{' '}
            <span>
              <a href={props.data ? props.data.developer : '/'}></a>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}
