export const Titular = (props) => {
    return (
      <div id='team' className='text-center'>
        <div className='container'>
          <div className='row col-md-8 col-md-offset-2 section-title'>
            <h2>Directorio.</h2>
            <span>
              Equipo de profesionales en derecho a cargo del Licenciado Luis Eduardo Ugalde Tinoco cuenta con toda la experiencia y cualidades para brindarte el mejor servicio.
            </span>
          </div>
          <div id='row'>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.job}-${i}`} className='col-md-12 col-sm-12'>
                    <div className='thumbnail'>
                      {' '}
                      <div className='caption'>
                        <h4 href={d.titular}>
                            {d.name}
                        </h4>

                          <div className='row'>
                            <p>
                            {d.job}
                            </p>

                          <div className="row col-md-6">
                            <br />
                          <a href="pdf/Curriculum.pdf" target="_blank">
                            Curriculum Vitae
                          </a>
                          </div>
                          <div className="row col-md-6">
                            <br />
                          <a href={d ? d.email : '/'}>
                            luiseduardougalde@notaria04sjr.com
                          </a>
                          </div>

                          </div>

                      </div>
                      
                    </div>
                  </div>
                ))
              : 'cargando'}
          </div>
        </div>
      </div>
      )
  }