import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

export const EManual = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const images = [
    'img/manual/manu01.gif',
    'img/manual/manu02.gif',
    'img/manual/manu03.gif',
    'img/manual/manu04.gif',
    'img/manual/manu05.gif',
    'img/manual/manu06.gif',
    'img/manual/manu07.gif',    // Agrega más imágenes aquí
  ];
  const pasos = [
    'Abrir aplicación de "Correo"',
    'Ir a "Configuración"',
    'Clic en "Administrar cuentas" y después en "Agregar cuenta"',
    'Ir al final en "Avanzada" y dar clic en "Correo Electrónico"',
    'Capturar correo y usuario, los cuales son el mismo dato, la contraseña es la que cada cuenta tiene asignada',
    'Nombre de la cuenta es el nombre con el que se registrará en la aplicación, Enviar mensajes con este nombre es lo que verán las personas que reciban correos de esta cuenta',
    'El servidor entrante y saliente siempre será "notaria04sjr.com" y el tipo de cuenta es IMAP4, el resto de las opciónes se quedan igual, echo esto damos clic en "Iniciar Sesión" y LISTO!', // Agrega más pasos aquí
  ];

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        {/* Resto de tu código */}
        <div className='portfolio-items'>
        <br ></br>
            <h2>Configuración de correos institucionales - Notaria 04 Sjr</h2>
            <p>
             El presente es un manual interactivo, paso por paso, para configurar los correos institucionales de la Notaria 04, para poder apreciar cada animación, da clic sobre la misma, también podrás pasar de un paso a otro usando las flechas de izquierda y derecha.
            </p>
            <br ></br>
          {images.map((image, index) => (
            <div className='col-sm-6 col-md-4 col-lg-4' key={index}>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  <p>Paso {index+1}</p>
                  {' '}
                  <a href='#'
                    title='Ver imagen'
                    onClick={(e) => {
                      e.preventDefault();
                      openLightbox(index);
                    }}
                  >
                    <div className='hover-text'>      
                      <h4>{pasos[index]}</h4>
                    </div>
                    <img
                      src={image}
                      className='img-responsive'
                      alt='Ver imagen'
                    />
                  </a>{' '}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          imageTitle={`Paso ${photoIndex + 1} ${pasos[photoIndex]}`} // Encabezado con texto
        />
      )}
      <br ></br>
      <br ></br>
      <p>
        Muchas gracias por la atención.
      </p>
    </div>
  );
};
