export const JobBank = (props) => {
    return (
      <div id='jobBank' className='text-center'>
        <div className='container'>
          <div className='col-md-10 col-md-offset-1 section-title'>
            <h2>Bolsa de Trabajo</h2>
          </div>
          <div className='row'>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className='col-xs-12 col-md-12'>
                    {' '}
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                ))
              : 'Loading...'}
          </div>
          <div className="row col-lg-12 col-md-12 col-sm-12">
            <a href="https://forms.gle/dFN73k3vVKwfD3489" target="_blank" className='btn btn-custom btn-lg page-scroll'>
                Ir al formulario
            </a>
        </div>

        </div>
      </div>
    )
  }
  