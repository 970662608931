export const Pgpf = (props) => {
  return (
      <div className="modal fade" id="modal-requi-pgpf" tabIndex="-1" role="dialog" aria-labelledby="modal-requi-pgpf" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="modal-requi-pgpf">{props.data ? props.data.name1 : 'loading'}</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body">
                  <div>
                      <h4>Requisitos</h4>
                      
                      <div className="requi-text">
                      <ul>
                                {props.data
                                ? props.data.requires1.map((d, i) => (
                                    <li key={`${d}-${i}`}> {d}</li>
                                    ))
                                : 'cargando'}
                            </ul>   
                      </div>                    
                </div>
              <div className="modal-footer">
                <a className='navbar-brand page-scroll' href='#page-top'>
                <img src='img/logo-leut.png'alt='...' className='team-img-nav' />
                </a>
                  <a href="pdf/Pgpf.pdf" type="button" className="btn btn-requi-modal btn-lg" target="_blank">Descargar Requisitos</a>
              </div>
              </div>
          </div>
      </div>
  </div>
    )
  }   